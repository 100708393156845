<template>
  <!-- 作品 -->
  <div class="kj_service">
    <div
      class="current-nav centerText"
      v-if="Object.keys(ywData).length != 0"
      :style="{
        background: `url(${ywData.yewuImg}) no-repeat`
      }"
    ></div>
    <div class="all_no_data" v-else>
      <i class="el-icon-loading"></i>
      <p>数据正在加载</p>
    </div>
    <div class="kj_service_nav">
      <h2>{{ ywData.yewuMsg }}</h2>
      <h4>{{ ywData.yewuText }}</h4>
      <h6 v-html="ywData.yewuHtml"></h6>
      <div class="nav_box">
        <div class="nav_box_left">
          <img :src="wgData.waiguanImg" alt="" style="width:100%" />
        </div>
        <div class="nav_box_right">
          <h1>{{ wgData.waiguan }}</h1>
          <h3>{{ wgData.waiguanYw }}</h3>
          <div class="box_html" v-html="wgData.waiguanHtml"></div>
        </div>
      </div>
      <div class="nav_box">
        <div class="nav_box_left">
          <img :src="jgData.jiegoImg" alt="" style="width:100%" />
        </div>
        <div class="nav_box_right">
          <h1>{{ jgData.jiego }}</h1>
          <h3>{{ jgData.jiegoYw }}</h3>
          <div class="box_html" v-html="jgData.jiegoHtml"></div>
        </div>
      </div>
      <div class="nav_box">
        <div class="nav_box_left">
          <img :src="ppData.pinpaiImg" alt="" style="width:100%" />
        </div>
        <div class="nav_box_right">
          <h1>{{ ppData.pinpai }}</h1>
          <h3>{{ ppData.pinpaiYw }}</h3>
          <div class="box_html" v-html="ppData.pinpaiHtml"></div>
        </div>
      </div>
      <div class="nav_box">
        <div class="nav_box_left">
          <img :src="gylData.gonyingImg" alt="" style="width:100%" />
        </div>
        <div class="nav_box_right">
          <h1>{{ gylData.gonying }}</h1>
          <h3>{{ gylData.gonyingYw }}</h3>
          <div class="box_html" v-html="gylData.gonyingHtml"></div>
        </div>
      </div>
      <div class="nav_box">
        <div class="nav_box_left">
          <img :src="yjData.yingjianImg" alt="" style="width:100%" />
        </div>
        <div class="nav_box_right">
          <h1>{{ yjData.yingjian }}</h1>
          <h3>{{ yjData.yingjianYw }}</h3>
          <div class="box_html" v-html="yjData.yingjianHtml"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      ywData: {},
      wgData: {},
      jgData: {},
      ppData: {},
      gylData: {},
      yjData: {}
    }
  },
  watch: {},
  methods: {
    getData () {
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.ywData = res.data.data.yewuMsg
          this.gylData = res.data.data.gonying
          this.jgData = res.data.data.jiego
          this.ppData = res.data.data.pinpai
          this.wgData = res.data.data.waiguan
          this.yjData = res.data.data.yingjian
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.kj_service {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  .current-nav {
    background-size: 100% 100% !important;
  }
  .no_data {
    height: 400px;
  }
  .kj_service_nav {
    line-height: 1.3;
    width: 90%;
    margin: 50px auto;
    h2,
    h4,
    h6 {
      font-weight: bold;
    }
    h2 {
      font-size: 40px;
      letter-spacing: 8px;
    }
    h4 {
      font-size: 20px;
      margin-top: 6px;
    }
    h6 {
      margin: 30px 0;
      font-size: 18px;
      line-height: 1.5;
    }
    .nav_box {
      margin-top: 100px;
      display: flex;
      justify-content: space-between;
      .nav_box_left {
        width: 55%;
        display: flex;
        align-items: center;
      }
      .nav_box_right {
        width: 36%;
        h1 {
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 6px;
          line-height: 1;
        }
        h3 {
          font-size: 20px;
          font-weight: bold;
          line-height: 2;
        }
      }
    }
  }
}
</style>
